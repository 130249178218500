<template>
    <div>
        <el-popover ref="popover" placement="bottom" width="4%" trigger="click" class="mr-2">
            <div v-for="(localIcon, index) in icons" :key="index" class="flex">
                <span class="p-2">
                    <el-button circle size="large" style="font-size: 16px;" :style="{ color: localIcon.color }" :icon="localIcon.code" @click="selectIcon(localIcon)" />
                </span>
                <div class="m-auto p-1 font-serif font-light">
                    {{ localIcon.name }}
                </div>
            </div>
            <el-button class="w-11 h-11 p-2" style="font-size: 16px;" :style="{ color: selectedIcon.color }" :icon="selectedIcon.code" circle slot="reference" />
        </el-popover>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: Object,
        },

        icons: {
            type: Array,
            required: true,
        },
    },

    watch: {
        value: {
            async handler() {
                this.selectedIcon = { ...this.value };
            },
        },
    },

    data() {
        return {
            selectedIcon: this.value || this.icons[0],
        };
    },

    methods: {
        selectIcon(icon) {
            this.selectedIcon = icon;
            this.$refs.popover.doClose();
            this.$emit("input", icon);
        },
    },
};
</script>
<style>
.el-popover {
    min-width: 62px;
    height: 300px;
    overflow: auto;
    padding: 5px;
}
</style>
